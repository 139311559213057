import React, { useState } from "react";
import { renderIcon } from "../utils";
const OtherQuestionsCard = ({
  question,
  borderColor,
  onClick,
  cursor,
  isDemo,
}) => {
  const [cardHovered, setCardHovered] = useState(false);
  return (
    <div
      className="example-question"
      onClick={() => onClick(isDemo ? question : question.question)}
      style={{
        borderColor: isDemo ? null : borderColor,
        color: cardHovered ? borderColor : "#152540",
        cursor: cursor,
        minHeight: "32px",
        boxShadow: cardHovered
          ? `0px 0px 0px 1px ${borderColor} inset`
          : "none",
        maxHeight: "62px",
      }}
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
    >
      {isDemo ? null : <span>{renderIcon(question.icon, null, 2)}</span>}
      {isDemo ? question.split("+").join(" ") : question.question}
    </div>
  );
};

const OtherQuestions = ({
  exampleQuestions,
  onClick,
  borderColor,
  cursor,
  isDemo = false,
}) => {
  return (
    <div className="example-questions other-questions">
      {exampleQuestions.map((question, index) => (
        <OtherQuestionsCard
          key={index + 1}
          question={question}
          borderColor={borderColor}
          onClick={onClick}
          cursor={cursor}
          isDemo={isDemo}
        />
      ))}
    </div>
  );
};

export default OtherQuestions;
